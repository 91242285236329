.related-stories .related-stories-header h2 {
  letter-spacing: 0;
  margin-bottom: 67px;
  font-family: Pitch Medium, sans-serif;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.16667em;
}

@media (width <= 768px) {
  .related-stories .related-stories-header h2 {
    font-size: 1em;
    line-height: 1.75em;
  }
}

.related-stories .post-listing {
  max-width: 1000px;
}

.related-stories .post-listing .post-listing__item {
  transition: -webkit-transform .3s, transform .3s;
  position: relative;
}

.related-stories .post-listing .post-listing__item:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.related-stories .post-listing .post-listing__item h3 {
  letter-spacing: -.72px;
  border-bottom: 1px solid #162224;
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-size: 2.25em;
  line-height: 1.16667em;
}

@media (width <= 768px) {
  .related-stories .post-listing .post-listing__item h3 {
    font-size: 1.75em;
    line-height: 1.28571em;
  }
}

.related-stories .post-listing .post-listing__item .post__details {
  justify-content: space-between;
  align-items: baseline;
  display: -webkit-flex;
  display: flex;
}

@media (width <= 768px) {
  .related-stories .post-listing .post-listing__item .post__details {
    display: block;
  }
}

.related-stories .post-listing .post-listing__item p {
  color: #434c4e;
  opacity: .9373;
  pointer-events: none;
  font-size: 1.125em;
  line-height: 1.44444em;
}

.related-stories .post-listing .post-listing__item .read-more {
  letter-spacing: 1.4px;
  text-transform: uppercase;
  white-space: nowrap;
  margin-left: 7.14286em;
  font-family: Eina Bold, sans-serif;
  font-size: .875em;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
}

@media (width <= 768px) {
  .related-stories .post-listing .post-listing__item .read-more {
    margin-left: 0;
  }
}

.related-stories .post-listing .post-listing__item a {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
/*# sourceMappingURL=related-stories.css.map */
