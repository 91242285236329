@import "../config";
@import "../mixins/mixins-base";

.related-stories {
	.related-stories-header	h2 {
		@include font-alt();
		letter-spacing: 0;
	    font-size: _em(24);
	    line-height: _em(28, 24);
	    margin-bottom: 67px;

		@include breakpoint(sm) {
			font-size: _em(16);
			line-height: _em(28, 16);
		}
	}

	.post-listing {

		max-width: 1000px;

		.post-listing__item {

			position: relative;
			transition: transform 0.3s;

			&:hover {
				transform: translateY(-5px);
			}

			h3 {
				font-size: _em(36);
	    		line-height: _em(42, 36);
	    		letter-spacing: -0.72px;

				border-bottom: 1px $color-black solid;
				padding-bottom: 15px;
				margin-bottom: 15px;

				@include breakpoint(sm) {
					font-size: _em(28);
					line-height: _em(36, 28);
				}
			}

			.post__details {
				display: flex;
				justify-content: space-between;
				align-items: baseline;

				@include breakpoint(sm) {
					display: block;
				}
			}

			p {
				font-size: _em(18);
	    		line-height: _em(26, 18);

				color: $color-gray;
				opacity: 0.9373;
				pointer-events: none;
			}

			.read-more {
				@include font(bold);
				font-size: _em(14);
				line-height: _em(14, 14);
				letter-spacing: 1.4px;
				text-transform: uppercase;
				white-space: nowrap;
				margin-left: _em(100, 14);

				@include breakpoint(sm) {
					margin-left: 0;
				}	
			}

			a {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}

	}
}